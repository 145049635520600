<template lang="">

<div v-resize="onResize">
    <!-- {{$vuetify.breakpoint.name }} -->
    <v-row v-if="$vuetify.breakpoint.xs">
        <!-- Only in Phone and Ipad -->
        <v-col sm="12" xs="12">
            <v-container style="height: 100%;" class="pt-1">
                <v-row>
                    <v-img position="center center" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" contain max-height="17vh" max-width="17vw"></v-img>
                </v-row>
                <v-row>
                    <v-col cols="12" style="padding: 0px;">
                        <v-img style="transform: translate(-0px,0%);" position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-ฉันเป็นไหม.png" contain max-height="280px"></v-img>
                    </v-col>

                    <v-col cols="12" style="padding-top:0px">
                        <div class="justify-center flex-column pl-5">
                            <div class="pb-2">
                                <v-img class="zoom" @click="goAzq" position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-แบบสอบถาม.png" contain max-height="70px" max-width="250px"></v-img>
                            </div>

                            <div class="pb-1">
                                <v-img @click="gotoEisai" position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-ความรู้.png" contain max-height="37px" max-width="200px"></v-img>
                            </div>
                            <div>
                                <v-img @click="goLogin" position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-เข้าสู่ระบบ.png" contain max-height="35px" max-width="100px"></v-img>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-col class="footer-sm" sm="12" xs="12">
                    <v-row>
                        <v-col cols="5">
                            <div class="d-flex justify-start align-center">
                                <div class="pl-0 ma-0">
                                    <v-img position="left center" src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png" contain max-height="15vh" max-width="25vw"></v-img>
                                </div>
                                <div class="ma-0">
                                    <v-img position="left center" src="@/assets/img/checkmong/logo/royyak_logo.png" contain max-height="6vh" max-width="6vw"></v-img>
                                </div>

                            </div>
                            <v-row class="d-flex justify-start align-center pl-5">
                                <span style="font-size:10px;z-index: 99;">Powered by &nbsp;<a @click="goRoyyak" href=""> Royyak</a></span>
                            </v-row>
                        </v-col>
                        <v-col cols="7">

                        </v-col>
                    </v-row>
                    <v-row>
                        <span class="ma-0" style="text-align:center; font-size:0.4rem;text-align: center;
                        font-size: 0.35rem;
                        padding-top: 5px;
                        padding-right: 0px;
                        padding-bottom:1px;
                        line-height: 5.5px;">
                            แบบประเมินผล THAI-AZQ 21 ข้อถูกพัฒนาและประเมินความเที่ยงตรงโดยอดิศักดิ์ กิตติสาเรศ1 ,
                            สุรัตน์ ตันประเวช1 , สุขเจริญ ตั้งวงไชย2 , ศิวาพร จันทร์กระจ่าง1

                            1.หน่วยประสาทวิทยา ภาควิชาอายุรศาสตร์ คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่
                            2.ภาควิชาจิตเวชศาสตร์ คณะแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
                        </span>
                    </v-row>
                </v-col>

            </v-container>

        </v-col>
        <spand style="font-size:10px; position:absolute; bottom:12px;right:7px">TH-UAR-RY-221-03</spand>
    </v-row>
    <v-row v-else-if="$vuetify.breakpoint.sm">
        <!-- Only in Phone and Ipad -->
        <v-col sm="12" xs="12">
            <v-container style="height: 100%;" class="pt-2">
                <v-row>
                    <v-img position="center center" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" contain max-height="15vh" max-width="15vw"></v-img>
                </v-row>
                <v-row>
                    <v-col cols="12" style="padding: 0px;">
                        <v-img style="transform: translate(-px,0%);" position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-ฉันเป็นไหม.png" contain max-height="265px"></v-img>
                    </v-col>

                    <v-col cols="12">
                        <div class="justify-center flex-column pl-5">
                            <div class="pb-2">
                                <v-img class="zoom" @click="goAzq" position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-แบบสอบถาม.png" contain max-height="150px" max-width="350px"></v-img>
                            </div>

                            <div class="pb-1">
                                <v-img @click="gotoEisai" position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-ความรู้.png" contain max-height="90px" max-width="280px"></v-img>
                            </div>
                            <div>
                                <v-img @click="goLogin" position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-เข้าสู่ระบบ.png" contain max-height="75px" max-width="140px"></v-img>
                            </div>
                        </div>
                    </v-col>
                </v-row>

                <v-col class="footer-sm" sm="12" xs="12">
                    <v-row>
                        <v-col cols="3" style=" transform: translate(0%,-15%);">
                            <div class="pl-7 ma-1 d-flex justify-start align-center">
                                <div class="ma-0">
                                    <v-img position="left center" src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png" contain max-height="10vh" max-width="12vw"></v-img>
                                </div>
                                <div class="ma-0">
                                    <v-img position="left center" src="@/assets/img/checkmong/logo/royyak_logo.png" contain max-height="4vh" max-width="4vw"></v-img>
                                </div>
                            </div>

                            <v-row class="pl-13 d-flex justify-start align-center">
                                <span style="font-size:10px;z-index: 99">Powered by &nbsp;<a @click="goRoyyak" href=""> Royyak</a></span>
                            </v-row>
                        </v-col>
                        <v-col cols="9">
                            <div class="ml-1 mr-1 rounded-lg" style="text-align:center; font-size:0.8rem">
                                <span>
                                    แบบประเมินผล THAI-AZQ 21 ข้อถูกพัฒนาและประเมินความเที่ยงตรงโดยอดิศักดิ์ กิตติสาเรศ1 ,
                                    สุรัตน์ ตันประเวช1 , สุขเจริญ ตั้งวงไชย2 , ศิวาพร จันทร์กระจ่าง1

                                    1.หน่วยประสาทวิทยา ภาควิชาอายุรศาสตร์ คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่
                                    2.ภาควิชาจิตเวชศาสตร์ คณะแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
                                </span>
                            </div>

                        </v-col>

                    </v-row>

                </v-col>

            </v-container>
        </v-col>
        <spand style="font-size:10px; position:absolute; bottom:2px;right:7px">TH-UAR-RY-221-03</spand>
    </v-row>

    <v-row v-else-if="$vuetify.breakpoint.md">
        <v-row>
            <v-col lg="7" md="8" sm="12" xs="12">
                <v-container style="height: 100%; transform: translate(0%,0%);">
                    <v-row>
                        <div style="transform: translate(-30%,7%);">
                            <v-img position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" contain max-height="15vh" max-width="15vw"></v-img>
                        </div>

                        <div style="height: 100%; transform: translate(-5%,0%);">
                            <v-img position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-ฉันเป็นไหม.png" contain height="35vh"></v-img>
                        </div>
                    </v-row>
                    <v-row class="pt-0">
                        <v-col cols="12" style="padding:0">
                            <v-img class="zoom" @click="goAzq" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-แบบสอบถาม.png" contain max-height="100px" max-width="350px"></v-img>
                        </v-col>
                        <v-col cols="12" style="padding:0">
                            <div class="pt-2">
                                <v-img class="zoom" @click="gotoEisai" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-ความรู้.png" contain max-height="60px" max-width="305px"></v-img>
                            </div>
                        </v-col>
                        <v-col cols="12" style="padding:0">
                            <div class="pt-1">
                                <v-img class="zoom" @click="goLogin" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-เข้าสู่ระบบ.png" contain max-height="50px" max-width="148px"></v-img>
                            </div>
                        </v-col>

                    </v-row>

                </v-container>

            </v-col>
            <v-col lg="5" md="2" xs="12">
                <!-- <v-row>
                    <v-col cols="5">
                        <div style="transform: translate(-30%,0%);">
                            <v-img position="center center" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" contain max-height="25vh"></v-img>
                        </div>
                    </v-col>
                    <v-col cols="7">
                        <div style="transform: translate(-20%,0%);">
                            <v-img position="center center" src="@/assets/img/checkmong/landing-page/02-Eisai-ป้า.png" contain max-height="70vh" max-width="50vh"></v-img>
                        </div>
                    </v-col>
                </v-row> -->

            </v-col>

        </v-row>
        <v-row style="position: absolute;bottom: 1vh;left: 2vw;" class="d-flex justify-space-around align-center">
            <v-col cols="9">
                <v-row>
                    <span class="ml-1 mr-1 rounded-lg" style="text-align:center; font-size:0.7rem">
                        แบบประเมินผล THAI-AZQ 21ข้อถูกพัฒนาและประเมินความเที่ยงตรงโดยอดิศักดิ์ กิตติสาเรศ1 ,
                        สุรัตน์ ตันประเวช1 , สุขเจริญ ตั้งวงไชย2 , ศิวาพร จันทร์กระจ่าง1
                        1.หน่วยประสาทวิทยา ภาควิชาอายุรศาสตร์ คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่
                        2.ภาควิชาจิตเวชศาสตร์ คณะแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
                    </span>
                </v-row>
            </v-col>
            <v-col cols="3">
                <v-row>
                    <v-col cols="" style="padding:0px;">
                        <v-img position="center center" src="@/assets/img/checkmong/landing-page/logo-Eisai_03.png" contain max-height="10vh" max-width="10vw"></v-img>
                    </v-col>
                    <v-col cols="" style="padding:0px;transform: translate(0%,30%);">
                        <v-img position="center center" src="@/assets/img/checkmong/logo/royyak_logo.png" contain max-height="4vh" max-width="4vw"></v-img>
                    </v-col>
                    <v-col cols="" style="padding:0px;transform: translate(-30%,30%);">
                        <span style="font-size:8px">Powered by &nbsp;<a @click="goRoyyak" href=""> Royyak</a></span>
                    </v-col>
                </v-row>
                <!-- <span style="font-size:10px">Powered by &nbsp;<a @click="goRoyyak" href=""> Royyak</a></span> -->
            </v-col>

        </v-row>
        <spand style="font-size:10px; position:absolute; bottom:2px;right:7px">TH-UAR-RY-221-03</spand>

    </v-row>

    <v-row v-else-if="$vuetify.breakpoint.lg">
        <!-- Only in Phone and Ipad -->
        <v-row class="mt-7">
                    <v-container fluid style="transform: translate(0%,0%);">
                        <v-row>
                            <div style="transform: translate(0%,7%);">
                                <v-img position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" contain max-height="15vh" max-width="15vw"></v-img>
                            </div>
                        </v-row>
                        <v-row>
                            <div style="height: 100%; transform: translate(6%,0%);">
                                <v-img position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-ฉันเป็นไหม.png" contain height="35vh"></v-img>
                            </div>
                        </v-row>
                        <v-row class="pt-3" style="transform: translate(8.4%,0%);">
                            <v-col cols="12" style="padding:0">
                                <v-img class="zoom" @click="goAzq" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-แบบสอบถาม.png" contain max-height="200px" max-width="400px"></v-img>
                            </v-col>
                            <v-col>
                                <v-row>
                                <div class="pl-0 pt-7">
                                    <v-img class="zoom" @click="gotoEisai" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-ความรู้.png" contain max-height="100px" max-width="340px"></v-img>
                                </div>
                                <div class="pl-7 pt-7">
                                    <v-img class="zoom" @click="goLogin" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-เข้าสู่ระบบ.png" contain max-height="100px" max-width="200px"></v-img>
                                </div>
                            </v-row>
                        </v-col>
                        </v-row>

                    </v-container>


        </v-row>
        <div style="position: absolute;bottom: 2vh;left: 12px;width: 100%;">
            <v-row class="d-flex justify-center">

                <v-col xl="10" lg="8" style="padding:0px;">
                    <div class="ml-15 mr-1 rounded-lg" style="text-align:center; font-size:0.7rem">
                        <span>
                            แบบประเมินผล THAI-AZQ 21ข้อถูกพัฒนาและประเมินความเที่ยงตรงโดยอดิศักดิ์ กิตติสาเรศ1 ,
                            สุรัตน์ ตันประเวช1 , สุขเจริญ ตั้งวงไชย2 , ศิวาพร จันทร์กระจ่าง1
                            1.หน่วยประสาทวิทยา ภาควิชาอายุรศาสตร์ คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่
                            2.ภาควิชาจิตเวชศาสตร์ คณะแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
                        </span>
                    </div>

                </v-col>
                <v-col xl="2" lg="4">
                    <v-row class="d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-col cols="" style="padding:0px;">
                            <v-img position="center center" src="@/assets/img/checkmong/logo/hhc_eisai2.png" contain max-height="120px" max-width="120px"></v-img>
                        </v-col>
                        <v-col cols="" style="padding:0px;transform: translate(15px,0%);">
                            <v-img position="center center" src="@/assets/img/checkmong/logo/royyak_logo.png" contain max-height="40px" max-width="40px"></v-img>
                        </v-col>
                        <v-col cols="" style="padding-top:20px;transform: translate(-30%,0%);">
                            <span style="font-size:10px">Powered by &nbsp;<a @click="goRoyyak" href=""> Royyak</a></span>
                        </v-col>
                    </v-row>

                </v-col>

            </v-row>
        </div>
        <spand style="font-size:10px; position:absolute; bottom:2px;right:7px">TH-UAR-RY-221-03</spand>

    </v-row>

    <v-row v-else>
        <!-- Only in Phone and Ipad -->

        <v-row class="mt-7">
            <v-row>
                <v-col xl="12">
                    <v-container style="height: 100%; width:70%; transform: translate(0%,0%);">
                        <v-row>
                            <div style="transform: translate(-30%,7%);">
                                <v-img position="left center" src="@/assets/img/checkmong/landing-page/02-Eisai-เช็คหมอง.png" contain max-height="15vh" max-width="15vw"></v-img>
                            </div>


                        </v-row>
                        <v-row>

                            <div style="height: 100%; transform: translate(-6%,0%);">
                                <v-img position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-ฉันเป็นไหม.png" contain height="35vh"></v-img>
                            </div>
                        </v-row>
                        <v-row class="pt-1">
                            <v-col cols="12" style="padding:0">
                                <v-img class="zoom" @click="goAzq" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-แบบสอบถาม.png" contain max-height="200px" max-width="450px"></v-img>
                            </v-col>
                         
                            <v-col cols="12" style="padding:0px 4px 0px 0px">
                                <v-row>
                                <div class="pl-4 pt-7">
                                    <v-img class="zoom" @click="gotoEisai" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-ความรู้.png" contain max-height="100px" max-width="340px"></v-img>
                                </div>
                                <div class="pl-7 pt-7">
                                    <v-img class="zoom" @click="goLogin" position="center left" src="@/assets/img/checkmong/landing-page/02-Eisai-เข้าสู่ระบบ.png" contain max-height="100px" max-width="200px"></v-img>
                                </div>
                                </v-row>
                            </v-col>
                            
            
                        </v-row>

                    </v-container>

                </v-col>

            </v-row>

        </v-row>
        <div style="position: absolute;bottom: 2vh;left: 12px;width: 100%;">
            <v-row class="d-flex justify-center">

                <v-col xl="8" style="padding:0px;">
                    <div class="ml-15 mr-1 rounded-lg" style="text-align:center; font-size:0.7rem">
                        <span>
                            แบบประเมินผล THAI-AZQ 21ข้อถูกพัฒนาและประเมินความเที่ยงตรงโดยอดิศักดิ์ กิตติสาเรศ1 ,
                            สุรัตน์ ตันประเวช1 , สุขเจริญ ตั้งวงไชย2 , ศิวาพร จันทร์กระจ่าง1
                            1.หน่วยประสาทวิทยา ภาควิชาอายุรศาสตร์ คณะแพทยศาสตร์ มหาวิทยาลัยเชียงใหม่
                            2.ภาควิชาจิตเวชศาสตร์ คณะแพทยศาสตร์ จุฬาลงกรณ์มหาวิทยาลัย
                        </span>
                    </div>

                </v-col>
                <v-col xl="4">
                    <v-row class="d-flex justify-end">
                        <v-spacer></v-spacer>
                        <v-col cols="" style="padding:0px;">
                            <v-img position="center center" src="@/assets/img/checkmong/logo/hhc_eisai2.png" contain max-height="120px" max-width="120px"></v-img>
                        </v-col>
                        <v-col cols="" style="padding:0px;transform: translate(15px,0%);">
                            <v-img position="center center" src="@/assets/img/checkmong/logo/royyak_logo.png" contain max-height="40px" max-width="40px"></v-img>
                        </v-col>
                        <v-col cols="" style="padding-top:20px;transform: translate(-30%,0%);">
                            <span style="font-size:10px">Powered by &nbsp;<a @click="goRoyyak" href=""> Royyak</a></span>
                        </v-col>
                    </v-row>

                </v-col>

            </v-row>
        </div>
        <spand style="font-size:10px; position:absolute; bottom:2px;right:7px">TH-UAR-RY-221-03</spand>
    </v-row>

</div>
</template>

<script>
import {
    WEB_END_POINT
} from '@/constants'
import {
    LIFFID
}
from '@/constants'
export default {
    data() {

        return {
            windowSize: {
                x: 0,
                y: 0,
            },
        };
    },
    // watch: {
    //     '$store.state.azq_ans.stop_testing'(stop_testing) {
    //         //console.log(stop_testing)
    //         if (stop_testing == '1'){
    //             //console.log('refresh')
    //             this.$forceUpdate();
    //             const azq_ans_temp = this.$store.state.azq_ans

    //             //console.log(this.azq_ans_temp)
    //             // //console.log(current_ans)
    //             this.$store.commit('setAzqAns', {
    //                 ...azq_ans_temp,
    //                 'stop_testing':'0'

    //             });
    //             this.$forceUpdate();
    //             this.$store.commit('setAzqAns', {});
    //         }
    //     }
    // },

    methods: {
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
        },
        goRoyyak() {
            window.open("https://royyak.com/");
        },
        gotoEisai() {
            if (this.$vuetify.breakpoint.xs) {
                // window.open("https://hhcthailand.com/category/health/brain/");
                window.open("https://www.checkmong.com/select_brain_library");
            } else {
                window.open("https://www.checkmong.com/select_brain_library");
                // window.open('https://hhcthailand.com/category/health/brain/');
            }

        },
        goAzq() {

            if (this.$vuetify.breakpoint.xs) {
                window.open("https://liff.line.me/1657454775-NraD6Rye");
            } else {
                // window.open(WEB_END_POINT+'/azq_public');
                this.$router.push('/azq_public')
            }

        },
        goLogin() {
            if (this.$vuetify.breakpoint.xs) {
                window.open("https://liff.line.me/" + LIFFID);
            } else {
                // window.open(WEB_END_POINT+'/login');
                this.$router.push('/login')
            }
        }
    },
    beforeCreate() {
        //console.log('landing page .... loadedxx')
        const azq_ans_temp = this.$store.state.azq_ans.stop_testing
        //console.log(azq_ans_temp)
        if (azq_ans_temp == '1') {
            this.$store.commit('setAzqAns', {});
            this.$router.go(0);
        }
    },
    mounted() {
        this.$store.commit('setAzqAns', {});
        this.onResize()
    },
    created() {
        //console.log('created')
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-CSGDKV81D4');

    },
    beforeUpdate() {

    },
    updated() {
        //console.log('updated')

    }

};
</script>

<style scoped>
.zoom {
    transition: transform 0s;
}

.zoom:hover {
    transform: scale(1.007);
    /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.footer-sm {
    position: absolute;
    bottom: 0;
    left: 0;
}
</style>

            <!-- <v-btn
          @click="$router.push('/azq_public')"
          elevation="0"
          :ripple="false"
          height="43"
          class="
            font-weight-bold
            text-uppercase
            btn-default
            bg-gradient-purple
            py-2
            px-6
            me-2
            mt-6
            mb-2
          "
          color="#5e72e4"
          small
          >แบบทดสอบสมองเสื่อม</v-btn
        > -->
